import store from '@/store'
import Echo from 'laravel-echo'

import { env } from '@/helpers'
import { Channel, Event, SERVER_BASE_URL } from '@/consts'

/**
 *
 * @param {{
 *     token: string,
 *     user_id:string
 *     company_id:string
 * }} options
 */
const initializePusherJS = (options) => {
	window.Pusher = require('pusher-js')
	window.$pusher = {}

	window.Echo = new Echo({
		cluster: env('PUSHER_CLUSTER'),
		encrypted: true,
		broadcaster: 'pusher',
		key: env('PUSHER_APP_KEY'),
		authEndpoint: `${SERVER_BASE_URL}/broadcasting/auth`,
		auth: {
			headers: {
				Authorization: `Bearer ${options.token}`,
			},
		},
	})

	if (!store.getters.user.isStore) {
		window.$pusher.user = window.Echo.join(
			Channel.ActiveUser(options.company_id)
		)
	}
	window.$pusher.notification = window.Echo.join(
		Channel.Notifications(options.user_id)
	)

	store.commit('pusher/SET', { isReady: true }, { global: true })
}

export default initializePusherJS
